import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import "dayjs/locale/hu";
import { useEffect, useState } from "react";

type TimePickerProps = {
  value: any;
  onChange: (value: any | null) => void;
};

export function CustomTimePicker({ value, onChange }: TimePickerProps) {
  const [time, setTime] = useState<Date | null>(null);

  useEffect(() => {
    if ((value as string) && (value as string).length < 6) {
      const [hours, minutes] = value.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0); // beállítja az órát és percet
      setTime(date); // beállítja a TimePicker kezdőértékét

      // console.log("TimePickerProps value string", dayjs(date));
    } else {
      // console.log("TimePickerProps value new Date", value);

      setTime(new Date(value)); // beállítja a TimePicker kezdőértékét
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
      <TimePicker value={time} onChange={onChange} />
    </LocalizationProvider>
  );
}
