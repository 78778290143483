import axios from "axios";

export async function deleteAdminAction(id: number) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  return await axios
    .delete(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
