import axios from "axios";
import { store } from "../../store";

export async function modifyTicketAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  const { title, id } = store.getState().ticket;
  const dataToSend = {
    id,
    title,
  };

  return await axios
    .put(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/tickets`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
