import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  CustomDatePicker,
  CustomModal,
  Form,
  InfoModal,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { RootState } from "../../../store";
import {
  createMessageConstantAction,
  modifyMessageConstantAction,
} from "../../../actions/message-constant";
import {
  resetMessageConstants,
  setMessageConstantValidFrom,
  setMessageConstantValidTo,
} from "../../../constants/reducers/message-constant.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type MutateMessageConstantModalProps = {
  handleVisible: () => void;
};

const style = {
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function MutateMessageConstantModal({
  handleVisible,
}: MutateMessageConstantModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { validFrom, validTo, id } = useAppSelector(
    (state: RootState) => state.messageConstant
  );

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: id ? modifyMessageConstantAction : createMessageConstantAction,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.messageConstantQuery]);
      dispatch(resetMessageConstants());
      return handleVisible();
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSave = () => {
    mutate();
  };

  const handleValidFromChange = (value: Date | null) => {
    dispatch(setMessageConstantValidFrom(value));
  };

  const handleValidToChange = (value: Date | null) => {
    dispatch(setMessageConstantValidTo(value));
  };

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <h3 className="title-text-h3">
              {t("message-constant-create-modal-title")}
            </h3>
            <CustomDatePicker
              value={validFrom}
              onChange={handleValidFromChange}
            />
            <CustomDatePicker value={validTo} onChange={handleValidToChange} />
            <CustomButton
              onClick={handleSave}
              title={t("save")}
              type="outlined"
            />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("message-constant-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
