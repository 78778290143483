import axios from "axios";
import { store } from "../../store";

export async function modifyContactAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const { email, phoneNumber, id } = store.getState().contact;

  const params = {
    id,
    email,
    phoneNumber,
  };

  return await axios
    .put(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/contacts`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
