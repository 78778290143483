import axios from "axios";
import { store } from "../../store";

export async function modifyOpeningHourAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const { id, name } = store.getState().openingHours;
  const dataToSend = {
    id,
    name,
  };

  return await axios
    .put(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/opening-hours`,
      dataToSend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
