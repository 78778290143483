import axios from "axios";
import { store } from "../../store";

export async function modifyOpeningHourConstantAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const { id, opened, openedFrom, openedTo, type } =
    store.getState().openingHoursConstant;

  const modifiedOpenedFrom = `${new Date(openedFrom)
    .getHours()
    .toString()
    .padStart(2, "0")}:${new Date(openedFrom)
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  const modifiedOpenedTo = `${new Date(openedTo)
    .getHours()
    .toString()
    .padStart(2, "0")}:${new Date(openedTo)
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  const dataToSend = {
    id,
    opened,
    openedFrom: modifiedOpenedFrom,
    openedTo: modifiedOpenedTo,
    type,
  };

  return await axios
    .put(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/opening-hours-constant`,
      dataToSend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
