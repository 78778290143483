import { useQuery } from "@tanstack/react-query";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import { useAppTranslation } from "../../constants/hooks";
import {
  MessageColumns,
  MessageConstantTableHeaders,
  MutateMessageConstantModal,
} from "./components";
import {
  useAppDispatch,
  useAppState,
} from "../../constants/hooks/global-hooks";
import { getAllMessageConstantAction } from "../../actions/message-constant";
import { resetMessage } from "../../constants/reducers/message.reducer";
import { MutateMessageModal } from "./mutate-message";
import { QueryKeys } from "../../constants/query-keys";

export function MessagePage() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateModalVisible: false,
    mutateMessageModalVisible: false,
  });

  const { data, error, isLoading } = useQuery({
    queryKey: [QueryKeys.messageConstantQuery],
    queryFn: getAllMessageConstantAction,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutateModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));
    if (state.mutateModalVisible) {
      dispatch(resetMessage());
    }
  };

  const handleMutateMessageModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateMessageModalVisible: !state.mutateMessageModalVisible,
    }));
    if (state.mutateMessageModalVisible === true) {
      dispatch(resetMessage());
    }
  };

  return (
    <main className="page">
      <h1>{t("message-page-title")}</h1>
      <CustomButton
        type="contained"
        title={t("message-button-title")}
        onClick={handleMutateModal}
      />
      {!isLoading && (
        <CustomTable
          data={data?.messageConstants}
          headers={[...[""], ...MessageConstantTableHeaders]}
          Columns={MessageColumns}
          onModifyClick={handleMutateModal}
          onModifyOffData={handleMutateMessageModal}
        />
      )}
      {state.mutateMessageModalVisible && (
        <MutateMessageModal handleVisible={handleMutateMessageModal} />
      )}

      {state.mutateModalVisible && (
        <MutateMessageConstantModal handleVisible={handleMutateModal} />
      )}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
