import axios from "axios";

export async function createLanguageAction(params: FormData) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/languages/create`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => res.data);
}
