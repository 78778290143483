import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useAppDispatch,
  useAppNavigate,
  useAppParams,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import {
  createNewsAction,
  getNewsAction,
  modifyNewsAction,
} from "../../../actions/news";
import { NewsMutationForm } from "./components";
import { ErrorModal, HorizontalLinearStepper } from "../../../components";

import "./mutate-news.page.scss";
import {
  resetNews,
  setNewsDescription,
  setNewsId,
  setNewsTitle,
} from "../../../constants/reducers/news.reducer";
import { RootState } from "../../../store";
import { getNewsConstantNewsCountAction } from "../../../actions/news-contant";
import { QueryKeys } from "../../../constants/query-keys";

export function MutateNews() {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const param = useAppParams();
  const navigate = useAppNavigate();
  const { id } = useAppSelector((state: RootState) => state.languages);

  const [state, setState] = useAppState({
    modalVisible: false,
    backwards: false,
    finish: false,
  });

  const getContant = useQuery({
    queryKey: [QueryKeys.newsQuery],
    queryFn: () => getNewsConstantNewsCountAction(parseInt(param.id!)),
  });

  const { mutate, error } = useMutation({
    mutationFn: (params: { languageId: number; constantId: number }) =>
      createNewsAction(params),
    onSuccess() {
      queryClient.invalidateQueries([
        QueryKeys.newsConstantQuery,
        QueryKeys.newsQuery,
      ]);
      dispatch(resetNews());
      setState((prevState) => ({ ...prevState, backwards: false }));
      if (state.finish) {
        navigate("/cms/news");
      }
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const newsQuery = useMutation({
    mutationFn: (params: { languageId: number; constantId: number }) =>
      getNewsAction(params),
    onSuccess(data) {
      dispatch(setNewsId(data.id));
      dispatch(setNewsTitle(data.title));
      dispatch(setNewsDescription(data.description));
      setState((prevState) => ({ ...prevState, backwards: true }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const modifyNews = useMutation({
    mutationFn: modifyNewsAction,
    onSuccess() {
      queryClient.invalidateQueries([QueryKeys.newsConstantQuery]);
      dispatch(resetNews());
      setState((prevState) => ({ ...prevState, backwards: false }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const languageData: any = queryClient.getQueryData([QueryKeys.languageQuery]);

  const handleSave = () => {
    if (state.backwards === true) {
      modifyNews.mutate();
    } else {
      const params = {
        languageId: id ? id : languageData?.languages[0].id,
        constantId: parseInt(param.id!),
      };
      mutate(params);
    }
  };

  const handleFinish = () => {
    setState((prevState) => ({ ...prevState, finish: true }));

    const params = {
      languageId: id,
      constantId: parseInt(param.id!),
    };
    mutate(params);
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  return (
    <main className="page mutate-news-page">
      <h1>{t("mutate-news-page-title")}</h1>
      <div className="data-creation-container">
        {!getContant.isLoading && (
          <>
            <div>
              <NewsMutationForm />
            </div>
            <div className="stepper">
              <HorizontalLinearStepper
                onNextClick={handleSave}
                onFinish={handleFinish}
                onPrevClick={(languageId: number) =>
                  newsQuery.mutate({
                    languageId,
                    constantId: parseInt(param.id!),
                  })
                }
                stepSkips={getContant.data}
              />
            </div>
          </>
        )}
      </div>

      {state.modalVisible && (
        <ErrorModal
          error={error ?? newsQuery.error}
          handleClose={handleClose}
        />
      )}
    </main>
  );
}
