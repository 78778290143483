import axios from "axios";

export async function createVersionAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const params = {};
  return await axios
    .post(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/versions`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
