import axios from "axios";

type getOpeningHourProps = {
  languageId: number;
  constantId: number;
};

export async function getOpeningHourAction({
  languageId,
  constantId,
}: getOpeningHourProps) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/opening-hours/one`, {
      params: { languageId, constantId },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
