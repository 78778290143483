import axios from "axios";
import { store } from "../../store";

type CreateMessageProps = {
  languageId: number;
  constantId: number;
};

export async function createMessageAction({
  languageId,
  constantId,
}: CreateMessageProps) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const { message } = store.getState().message;
  const dataToSend = {
    languageId,
    constantId,
    message,
  };

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/messages`,
      dataToSend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
