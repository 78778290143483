import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  ErrorModal,
  Form,
  Input,
  LoadingIcon,
} from "../../components";
import {
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../constants/hooks/global-hooks";
import { firstLoginAction } from "../../actions/user";
import "./first-login.page.scss";
import { validatePassword } from "../../constants/helper/validate.util";

const style = {
  "& .MuiTextField-root": { m: 1, width: "35ch" },
};

export function FirstLoginPage() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const [state, setState] = useAppState({
    password: "",
    passwordError: "",

    passwordCheck: "",
    passwordCheckError: "",

    modalVisible: false,
  });

  const queryClient = useQueryClient();

  const { isLoading, mutate, error } = useMutation({
    mutationFn: (data: any) => firstLoginAction(data),
    onSuccess: (data) => {
      queryClient.setQueryData(["user"], data);

      navigate("/cms/languages");
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handlePasswordChange = (value: string) => {
    setState((prevState) => ({ ...prevState, password: value }));
  };

  const handlePasswordCheckChange = (value: string) => {
    setState((prevState) => ({ ...prevState, passwordCheck: value }));
  };

  const handleSubmit = () => {
    setState((prevState) => ({
      ...prevState,
      passwordError: "",
      passwordCheckError: "",
    }));

    const psswordValidation = validatePassword(state.password);
    if (psswordValidation != null) {
      setState((prevState) => ({
        ...prevState,
        passwordError: t(psswordValidation),
      }));
      return;
    }

    if (state.password !== state.passwordCheck) {
      setState((prevState) => ({
        ...prevState,
        passwordCheckError: t("error_change_password_missmatch"),
      }));
      return;
    }

    mutate({
      password: state.password,
      passwordCheck: state.passwordCheck,
    });
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  return (
    <main>
      <Container style={style}>
        <h1>{t("first-login-title")}</h1>

        <Form direction="column" spacing={2}>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div>
              <div className="input-background">
                <Input
                  label={t("password")}
                  type="password"
                  value={state.password}
                  error={state.passwordError !== ""}
                  helperText={state.passwordError}
                  onChange={handlePasswordChange}
                  required={true}
                />
              </div>
              <div className="input-background">
                <Input
                  label={t("password-confirm")}
                  type="password"
                  value={state.passwordCheck}
                  error={state.passwordCheckError !== ""}
                  helperText={state.passwordCheckError  }

                  onChange={handlePasswordCheckChange}
                  required={true}
                />
              </div>
              <div className="login-button-container">
                <CustomButton
                  onClick={handleSubmit}
                  title={t("continue")}
                  type="outlined"
                />
              </div>
            </div>
          )}
        </Form>
      </Container>
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
