import axios from "axios";
import { store } from "../../store";

export async function createHolidayAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const { from, to, opened } = store.getState().holiday;

  const params = {
    from,
    to,
    opened,
  };
  return await axios
    .post(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/holiday`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
