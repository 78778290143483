import axios from "axios";

type getTicketProps = {
  languageId: number;
};

export async function getTicketAction({ languageId }: getTicketProps) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/tickets/one`, {
      params: { languageId },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
