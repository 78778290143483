export const OpeningHoursType = [
  {
    id: 0,
    title: "opening-hour",
  },
  {
    id: 1,
    title: "cashier-close",
  },
];
