import axios from "axios";
import { store } from "../../store";

export async function modifyMessageAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const { id, message } = store.getState().message;
  const dataToSend = {
    id,
    message,
  };

  return await axios
    .put(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/messages`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
