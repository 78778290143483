import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/hu";
import dayjs from "dayjs";

dayjs.locale("hu");

type DatePickerProps = {
  value: any;
  onChange: (value: any | null) => void;
};

export function CustomDatePicker({ value, onChange }: DatePickerProps) {
  // console.log("CustomDatePicker", dayjs(value));
  // console.log("CustomDatePicker v", value);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
      <DatePicker value={dayjs(value)} onChange={onChange} disablePast={true} />
    </LocalizationProvider>
  );
}
