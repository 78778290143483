import Button from "@mui/material/Button";
import "./button.scss";

type ButtonProps = {
  onClick: () => void;
  title: string;
  disabled?: boolean;

  type: "text" | "outlined" | "contained" | undefined;
};

export function CustomButton({ onClick, title, type, disabled }: ButtonProps) {
  return (
    <Button
      className="button"
      variant={type}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
}
