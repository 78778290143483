import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TicketTypeState } from "../state";

const initialState: TicketTypeState = {
  name: "",
  id: null!,
  price: null!,
  priceId: null!,
  unit: "",
  currency: "",
  ticketInfomationTitle: "",
  ticketInfomationDescription: "",
};

export const ticketTypeSlice = createSlice({
  name: "ticketType",
  initialState,
  reducers: {
    setTicketTypeId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    setTicketTypeName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setTicketTypePriceId: (state, action: PayloadAction<number>) => {
      state.priceId = action.payload;
    },

    setTicketTypePrice: (state, action: PayloadAction<number>) => {
      state.price = action.payload;
    },

    setTicketTypeUnit: (state, action: PayloadAction<string>) => {
      state.unit = action.payload;
    },

    setTicketTypeCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setTicketTypeInformationTitle: (state, action: PayloadAction<string>) => {
      state.ticketInfomationTitle = action.payload;
    },
    setTicketTypeInformationDescription: (
      state,
      action: PayloadAction<string>
    ) => {
      state.ticketInfomationDescription = action.payload;
    },

    resetTicketType: (state) => {
      state.name = "";
      state.id = null!;
      state.price = null!;
      state.unit = "";
      state.currency = "";
      state.ticketInfomationTitle = "";
      state.ticketInfomationDescription = "";
    },
  },
});

export const {
  setTicketTypeName,
  setTicketTypeId,
  setTicketTypePrice,
  setTicketTypePriceId,
  setTicketTypeUnit,
  setTicketTypeCurrency,
  setTicketTypeInformationTitle,
  setTicketTypeInformationDescription,
  resetTicketType,
} = ticketTypeSlice.actions;
