import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { useAppTranslation } from "../../constants/hooks";

type SelectProps = {
  title: string;
  options: any[];
  value: number;
  onChange: (value: any) => void;
};

export function Select({ value, title, options, onChange }: SelectProps) {
  const { t } = useAppTranslation();

  console.log("options", options);
  console.log("value", value);

  return (
    <FormControl fullWidth>
      <InputLabel variant="standard" htmlFor="uncontrolled-native">
        {title}
      </InputLabel>
      <NativeSelect value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => {
          return (
            <option value={option.id}>
              {t(option.title) ?? option.languageCode}
            </option>
          );
        })}
      </NativeSelect>
    </FormControl>
  );
}
