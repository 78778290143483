import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";

type CustomSwitchProps = {
  onChange: (value: boolean) => void;
  title: string;
  value: boolean;
};

export function CustomSwitch({ value, onChange, title }: CustomSwitchProps) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          name={"Switch"}
        />
      }
      label={title}
    />
  );
}
