import { useQuery } from "@tanstack/react-query";
import { useAppTranslation } from "../../constants/hooks";
import {
  useAppDispatch,
  useAppState,
} from "../../constants/hooks/global-hooks";
import { getAllOpeningHoursConstantAction } from "../../actions/opening-hours-constant";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import {
  MutateOpeningHoursConstantModal,
  OpeningHourColumns,
  OpeningHoursConstantTableHeaders,
} from "./components";
import { resetOpenedHours } from "../../constants/reducers/opening-hours.reducer";
import { MutateOpeningHourModal } from "./mutate-opening-hours";
import { QueryKeys } from "../../constants/query-keys";
import { resetOpenedHoursConstants } from "../../constants/reducers/opening-hours-constant.reducer";

export function OpeningHoursPage() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateModalVisible: false,
    mutateOpeningModalVisible: false,
  });

  const { data, error, isLoading } = useQuery({
    queryKey: [QueryKeys.openingHourConstantQuery],
    queryFn: getAllOpeningHoursConstantAction,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutateModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));

    dispatch(resetOpenedHoursConstants());

    if (state.mutateModalVisible === true) {
      dispatch(resetOpenedHours());
    }
  };

  const handleMutateOpeningModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateOpeningModalVisible: !state.mutateOpeningModalVisible,
    }));
    if (state.mutateOpeningModalVisible === true) {
      dispatch(resetOpenedHours());
    }
  };

  return (
    <main className="page">
      <h1>{t("opening-hours-page-title")}</h1>
      <CustomButton
        type="contained"
        title={t("opening-hours-button-title")}
        onClick={handleMutateModal}
      />
      {!isLoading && (
        <CustomTable
          data={data?.openingHoursConstants}
          headers={[...[""], ...OpeningHoursConstantTableHeaders]}
          Columns={OpeningHourColumns}
          onModifyClick={handleMutateModal}
          onModifyOffData={handleMutateOpeningModal}
        />
      )}
      {state.mutateOpeningModalVisible && (
        <MutateOpeningHourModal handleVisible={handleMutateOpeningModal} />
      )}

      {state.mutateModalVisible && (
        <MutateOpeningHoursConstantModal handleVisible={handleMutateModal} />
      )}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
