import axios from "axios";
import { store } from "../../store";

export async function modifyTicketTypeAction() {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");
  const {
    name,
    ticketInfomationDescription,
    ticketInfomationTitle,
    price,
    priceId,
    unit,
    currency,
    id,
  } = store.getState().ticketType;

  const dataToSend = {
    id,
    name,
    ticketPrice: {
      id: priceId,
      price: parseInt(price.toString()),
      currency,
      unit,
    },
    ticketInformation:
      ticketInfomationDescription && ticketInfomationTitle
        ? {
            title: ticketInfomationTitle,
            description: ticketInfomationDescription,
          }
        : null,
  };

  return await axios
    .put(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/tickets/ticket-type`,
      dataToSend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
