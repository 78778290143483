import axios from "axios";

type DeleteLanguageActionProps = {
  id: number;
};

export async function deleteLanguageAction({ id }: DeleteLanguageActionProps) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  return await axios
    .delete(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/languages/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
