import axios from "axios";

type FirstLoginProps = {
  password: string;
  passwordCheck: string;
};

export async function firstLoginAction(params: FirstLoginProps) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  return await axios
    .put(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/users/first-login`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
