import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomButton, Form, InfoModal, Input } from "../../../components";
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { modifyProfile } from "../../../actions/user";
import {
  resetUser,
  setUserName,
  setUserPassword,
} from "../../../constants/reducers/user.reducer";
import { RootState } from "../../../store";
import { QueryKeys } from "../../../constants/query-keys";
import "./profile-form.scss";

export function ProfileForm() {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { name, password } = useAppSelector((state: RootState) => state.user);
  const [state, setState] = useAppState({
    infoModalVisible: false,
    passwordConfirm: "",
  });

  const { mutate, error } = useMutation({
    mutationFn: modifyProfile,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.userQuery]);
      dispatch(resetUser());
      return navigate("/cms/languages");
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });
  const userData: any = queryClient.getQueryData([QueryKeys.userQuery]);
  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  const handlePasswordChange = (value: string) => {
    dispatch(setUserPassword(value));
  };

  const handlePasswordConfirmChange = (value: string) => {
    setState((prevState) => ({ ...prevState, passwordConfirm: value }));
  };
  const handleNameChange = (value: string) => {
    dispatch(setUserName(value));
  };

  const handleSave = () => {
    mutate();
  };

  return (
    <main className="page profile-form">
      <div className="data-creation-container">
        <Form direction="column" spacing={2}>
          <h3 className="title-text-h3">{t("profil-modify-title")}</h3>
          <Input
            onChange={() => {}}
            value={userData?.user.email}
            label={t("email")}
            type="text"
            disabled={true}
          />
          <Input
            required={true}
            onChange={handleNameChange}
            value={name}
            label={t("name-label")}
            type="text"
          />
          <Input
            required={true}
            onChange={handlePasswordChange}
            value={password}
            label={t("password")}
            type="password"
            iconPosition="end"
          />
          <Input
            required={true}
            onChange={handlePasswordConfirmChange}
            value={state.passwordConfirm}
            label={t("password-confirm")}
            type="password"
            iconPosition="end"
          />

          <CustomButton
            onClick={handleSave}
            title={t("save")}
            type="contained"
          />
        </Form>
      </div>

      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("language-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
