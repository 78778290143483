import axios from "axios";
import { store } from "../../store";

type CreateTicketProps = {
  languageId: number;
};

export async function createTicketAction({ languageId }: CreateTicketProps) {
  const token =
    sessionStorage.getItem("token") ?? localStorage.getItem("token");

  const { title } = store.getState().ticket;
  const dataToSend = {
    languageId,
    title,
  };

  return await axios
    .post(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/tickets`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
